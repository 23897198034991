
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import { NextComponentType } from "next"
import { AppContext, AppInitialProps, AppProps } from "next/app"
import { ErrorProps } from "next/error"
import Head from "next/head"
import { useRouter } from "next/router"
import { useEffect } from "react"

import { dataLayerPush } from "@app/shared/utils/tracking/dataLayer"

import "@casavo/habitat/style.css"
import "@shared/styles/css/carousel.css"
import "@shared/styles/css/chat-widget.css"
import "@shared/styles/css/style.css"
import "@shared/styles/css/variables.css"
import "tippy.js/dist/tippy.css"

export type CustomAppComponent = NextComponentType<
  AppContext,
  AppInitialProps,
  AppProps & { err: ErrorProps }
>

const CustomApp: CustomAppComponent = ({ Component, err, pageProps }) => {
  const router = useRouter()

  useEffect(() => {
    if (typeof window.dataLayer === "object") {
      dataLayerPush({
        event: "casavo.pageview",
        eventLabel: "PageView",
      })
    }
  }, [router.asPath])

  return (
    <>
      <Head>
        <meta content="width=device-width, initial-scale=1" name="viewport" />
      </Head>

      <Component {...pageProps} err={err} />
    </>
  )
}

const __Page_Next_Translate__ = CustomApp


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      
// @ts-ignore
    });
// @ts-ignore
  