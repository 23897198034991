type EventProps = {
  eventAction: string
  eventCategory?: string
  eventLabel: string | undefined
  eventName: string
  eventType?: string
  eventValue?: string
}

export const getPage = (): string => document.location.pathname + document.location.search

export const getLocation = (): string =>
  document.location.protocol +
  "//" +
  document.location.hostname +
  document.location.pathname +
  document.location.search

export const dataLayerPush = (params: Record<string, unknown>): void => {
  if (typeof window.dataLayer == "object") {
    window.dataLayer.push({
      location: getLocation(),
      page: getPage(),
      ...params,
    })
  }
}

export const trackUserInteraction = (action: string, label: string) => {
  if (typeof window.dataLayer == "object") {
    window.dataLayer.push({
      event: "casavo.user.interactions",
      eventAction: action,
      eventLabel: label,
    })
  }
}

export const sendEvent = ({ eventName, ...props }: EventProps): void => {
  if (typeof window.dataLayer !== "undefined") {
    dataLayerPush({ event: eventName, ...props })
  }
}

export const sendEventInteraction = ({
  eventAction,
  eventLabel,
  eventName,
  eventType = "generic",
  eventValue,
  eventCategory = "Website",
}: EventProps): void =>
  sendEvent({ eventAction, eventLabel, eventName, eventType, eventValue, eventCategory })

const FAQPaths = ["/faqs", "/it/faqs", "/es/faqs", "/pt/faqs", "/fr/faqs"]
const homePaths = ["/", "/it", "/es", "/pt", "/fr"]
const mortgagePaths = ["/it/mutui", "es/hipotecas"]
/**
 * @deprecated redirect to /
 *
 * TODO should we remove it?
 */
const sellCityPaths = [
  "/sell-house",
  "/it/vendi-casa",
  "/es/vender-casa",
  "/pt/vender-casa",
  "/fr/vendre-bien",
]
/**
 * @deprecated redirect to /
 *
 * TODO should we remove it?
 */
const oldSellHousePaths = ["/it/vendere-casa", "/es/vende-casa", "/fr/vendre-un-bien"]
const publishListingsPaths = [
  "/houses-for-sale",
  "/it/case-in-vendita",
  "/es/casas-en-venta",
  "/pt/comprar-casa",
  "/fr/acheter-bien",
]
const blogPaths = ["/blog", "/it/blog", "/pt/blog", "/fr/blog"]
const perfectMatchPaths = ["/it/trova-un-acquirente-perfetto", "/es/encuentra-al-comprador-perfecto"]
const guidesHomePaths = ["/it/guide", "/fr/guides"]
const guidesBuyerPaths = ["/it/guide/come-comprare-casa"]
const guidesSellerPaths = ["/it/guide/come-vendere-casa", "/fr/guides/comment-vendre-un-bien-immobilier"]
const matchPaths = ["/it/come-mettere-in-vendita-casa-trovare-acquirente-offerta"]

const priorityPaths = ["/it/go-priority", "/es/go-priority", "/en/go-priority", "/fr/go-priority"]

/**
 * @deprecated they return 404 :/
 *
 * TODO should we remove it?
 */
const sellersPaths = ["/it/sellers", "/es/sellers", "/en/sellers", "/fr/sellers"]

export const getCurrentPageFromPath = (path: string): string | undefined => {
  for (const iOfferPath of sellCityPaths) {
    const regex = new RegExp(`${escapeRegExp(iOfferPath)}(\/?).*$`, "g")
    if (regex.test(path)) return "SellCityPage"
  }

  for (const sellPath of oldSellHousePaths) {
    const regex = new RegExp(`${escapeRegExp(sellPath)}(\/?)$`, "g")
    if (regex.test(path)) return "OldSellPage"
  }

  for (const publishListingsPath of publishListingsPaths) {
    const regex = new RegExp(`${escapeRegExp(publishListingsPath)}(\/?)$`, "g")
    if (regex.test(path)) return "BuyerPage"
  }

  for (const mortgagePath of mortgagePaths) {
    const regex = new RegExp(`${escapeRegExp(mortgagePath)}(\/?)$`)
    if (regex.test(path)) return "MortgagePage"
  }

  for (const FAQPath of FAQPaths) {
    const regex = new RegExp(`${escapeRegExp(FAQPath)}(\/?).*$`)
    if (regex.test(path)) return "FAQPage"
  }

  for (const blogPath of blogPaths) {
    const regex = new RegExp(`${escapeRegExp(blogPath)}(\/?).*$`)
    if (regex.test(path)) return "BlogPage"
  }

  for (const perfectMatchPath of perfectMatchPaths) {
    const regex = new RegExp(`${escapeRegExp(perfectMatchPath)}(\/?)$`)
    if (regex.test(path)) return "PerfectMatchPage"
  }

  for (const guidesBuyerPath of guidesBuyerPaths) {
    const regex = new RegExp(`${escapeRegExp(guidesBuyerPath)}(\/?).*$`)
    if (regex.test(path)) return "GuidesBuyer"
  }

  for (const guidesSellerPath of guidesSellerPaths) {
    const regex = new RegExp(`${escapeRegExp(guidesSellerPath)}(\/?).*$`)
    if (regex.test(path)) return "GuidesSeller"
  }

  for (const guidesHomePath of guidesHomePaths) {
    const regex = new RegExp(`${escapeRegExp(guidesHomePath)}(\/?)$`)
    if (regex.test(path)) return "GuidesHome"
  }

  for (const matchPath of matchPaths) {
    const regex = new RegExp(`${escapeRegExp(matchPath)}(\/?)$`)
    if (regex.test(path)) return "MatchPage"
  }

  for (const priorityPath of priorityPaths) {
    const regex = new RegExp(`${escapeRegExp(priorityPath)}(\/?)$`)
    if (regex.test(path)) return "PriorityLanding"
  }

  for (const sellersPath of sellersPaths) {
    const regex = new RegExp(`${escapeRegExp(sellersPath)}(\/?).*$`)
    if (regex.test(path)) return "PerformanceLandingSellers"
  }

  for (const homePath of homePaths) {
    const regex = new RegExp(`${escapeRegExp(homePath)}(\/?)$`)
    if (regex.test(path)) return "Homepage"
  }

  return undefined
}

function escapeRegExp(text: string) {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&")
}
